<template>
  <div class="welfarebox">
    <CompHeader title="福利中心" />
    <div class="center">
      <div class="changebox">
        <div
          class="changeitem"
          v-for="item in changetypelist"
          @click="changeclick(item.id)"
          :key="item.id"
          :class="{ accahngeitem: item.id == actypeindex }"
        >
          {{ item.title }}
        </div>
      </div>
      <!-- 分享奖励 -->
      <div class="contnentbox" v-show="actypeindex == 1">
        <div class="usershare" v-if="this.user.anchor == 0">
          <div class="infoitem">
            <div class="infotext">
              <p class="text">我的下级人数</p>
              <p class="numbox">{{ share_user_count }}</p>
            </div>
          </div>
          <div class="sline"></div>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">下级充值奖励</p>
              <p class="numbox">{{ share_money }}</p>
            </div>
          </div>
        </div>
        <div class="usershare" v-else>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">累计推广人数</p>
              <p class="numbox">{{ userdata?.share_num }}</p>
            </div>
          </div>
          <div class="sline"></div>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">累计充值人数</p>
              <p class="numbox">{{ userdata?.change_num }}</p>
            </div>
          </div>
          <div class="sline"></div>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">累计充值金额</p>
              <p class="numbox">
                {{ userdata?.change_money }}
              </p>
            </div>
          </div>
          <div class="sline"></div>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">累计领取佣金</p>
              <p class="numbox">
                {{ userdata?.receive_money }}
              </p>
            </div>
          </div>
        </div>

        <div class="sharebtnbox">
          <div class="shareitem">
            <div class="lbg"></div>
            <div class="btninfo">
              <p>推广链接</p>
              <div class="copybtn" @click="textCopy(Url)">复制</div>
            </div>
          </div>
          <div class="shareitem">
            <div class="lbg"></div>
            <div class="btninfo">
              <p>推广码</p>
              <div class="copybtn" @click="shareimg">生成</div>
            </div>
          </div>
        </div>

        <div class="timeclickbox">
          <div
            class="timeitem"
            v-for="(item, i) in timedata"
            :key="i"
            @click="timeclick(item.id)"
            :class="{ actimeitem: timeacindex == item.id }"
          >
            {{ item.title }}
          </div>
          <div
            class="timeitem"
            @click="timeclick(3)"
            :class="{ actimeitem: timeacindex == 3 }"
          >
            日期筛选
            <div class="triangle-down"></div>
          </div>
        </div>

        <div class="tablebox">
          <div class="table-title">
            <div class="titleitem">下级名称</div>
            <div class="titleitem">充值金额</div>
            <div class="titleitem">推广奖励</div>
            <div class="titleitem t2">时间</div>
          </div>

          <div class="table-body">
            <div
              class="listitem"
              v-for="(item, i) in awards_records"
              :class="(i * 1 + 1) % 2 == 0 ? 'one' : 'two'"
              :key="i"
            >
              <div class="item i2">{{ item.next_user.name }}</div>
              <div class="item i2">{{ item.charge_bean }}</div>
              <div class="item i2">{{ item.bean }}</div>
              <div class="timeitem i2">
                {{ item.created_at }}
              </div>
            </div>
          </div>
        </div>
        <div class="pagebox">
          <Pagination
            :total="total"
            :page.sync="page"
            :limit.sync="per_page"
            @pagination="handleCurrentChange"
          />
        </div>
      </div>
      <!-- VIP箱子 -->
      <div class="contnentbox" v-show="actypeindex == 3">
        <div class="topbox">
          <div class="upbox">
            <div class="lbox">
              <img :src="vipdata?.user?.avatar" alt="" />
              <div class="viptitle">
                <div v-for="item in vipimgdata" :key="item.id">
                  <img
                    class="vipv"
                    :src="item.img"
                    v-if="item.id == vipdata?.user?.vip_level"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="rbox" @click="showtips">
              <img src="../../assets/newImages/tips-icon.png" alt="" />
              <p>说明</p>
            </div>
          </div>
          <div class="downbox">
            <div class="tbox">
              <div>下一级所需经验值</div>
              <div>
                {{ Number(vipdata?.user?.total_recharge) }}/{{
                  Number(vipdata?.next_level_info?.threshold)
                }}
              </div>
            </div>

            <div class="cbox">
              <div
                class="line"
                :style="`width:${parseInt(
                  (
                    (Number(vipdata?.user?.total_recharge) /
                      Number(vipdata?.next_level_info?.threshold)) *
                    100
                  ).toFixed(2)
                )}%`"
              ></div>
            </div>
            <div class="bbox">每充值1D币可获得1积分</div>
          </div>
        </div>
        <div class="bottombox">
          <div class="items">
            <div class="itemstitle">
              <img src="../../assets/newImages/vip-title1.png" alt="" />
            </div>
            <div class="consumer">
              <span>{{ vipdata?.vip?.rebate }}</span>
              %消费福利
              <div class="consumertext">（每日流水）</div>
            </div>
          </div>
          <div class="items">
            <div class="itemstitle">
              <img src="../../assets/newImages/vip-title2.png" alt="" />
            </div>
            <div class="clickitem" @click="govipbox">查看奖励</div>
          </div>
        </div>
      </div>
    </div>
    <!-- CDK 开箱 -->
    <div class="contnentbox" v-show="actypeindex == 2">
      <OpeningBox
        :itemsinfo="ItemInfo"
        :CDKtype="this.welfareboxdata.id"
        :iscdk="true"
        :cdkboxid="this.welfareboxdata.box_id"
      />
    </div>
    <!-- 日期选择 -->
    <div class="block" v-show="istimeclick">
      <div>
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :editable="false"
          value-format="yyyy-MM-dd"
          @change="changetime"
        >
        </el-date-picker>
        <div class="closetime">
          <div
            class="closebtn"
            @click="
              () => {
                this.istimeclick = false;
              }
            "
          >
            取消
          </div>
        </div>
      </div>
    </div>
    <div class="block" v-show="isshowerweima">
      <div class="scharebox">
        <div class="erweimatitle">
          <img
            src="../../assets/newImages/popup-close.png"
            alt=""
            @click="
              () => {
                this.isshowerweima = false;
              }
            "
          />
        </div>
        <div id="imgcontent" ref="capture">
          <div class="userinfobox">
            <div class="userinfo">
              <div class="headimgbox">
                <img :src="user.avatar" alt="" />
              </div>
              <div class="usertext">
                <div class="username">{{ user.name }}</div>
                <p>好友邀您来开箱</p>
              </div>
            </div>
            <div class="erweima">
              <img :src="showerweima" alt="" />
            </div>
          </div>
        </div>
        <div class="btnbox">
          <div class="btn" @click="saveclick">保存</div>
        </div>
      </div>
    </div>
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="istipsshow"
    >
      <CompPopup @close="showtips">
        <div class="frombox">
          <div class="fromtitle">VIP说明</div>
          <div class="frominput">
            1、经验值<br />
            用户每充值1D币获得一点经验值，可在查询记录页面查询经验值记录<br />
            2、VIP等级宝箱 <br />
            每升级一次VIP，将会自动开启对应等级的VIP宝箱
            每个VIP宝箱每24小时可开启一次，宝箱开启后会自动进入冷却倒计时，倒计时结束后才能再次开启宝箱。
            每一个VIP等级对应一个VIP宝箱，玩家只能开启对应VIP等级的宝箱<br />
            3、VIP奖励<br />
            玩家VIP等级升级后，会自动开启对应的VIP等级奖励，可在VIP页面进行查看<br />
            4、VIP等级制度<br />
            VIP0 充值0~1000 D币 <br />奖励:0.3%消费福利（任意充值金额后）<br />
            VIP1 充值1001~3000 D币
            <br />奖励:VIP1等级宝箱+0.3%消费福利（每日流水）+专属铭牌标识
            <br />
            VIP2充值:3001~5000 D币
            <br />奖励:VIP2等级宝箱+0.4%消费福利（每日流水）+专属铭牌标识
            <br />
            VIP3 充值:5001~10000 D币
            <br />奖励:VIP3等级宝箱+0.5%消费福利（每日流水）+专属铭牌标识
            <br />
            VIP4充值:10001~20000 D币
            <br />奖励:VIP4等级宝箱+0.6%消费福利（每日流水）+专属铭牌标识<br />
            VIP5充值:20001~50000 D币
            <br />奖励:VIP5等级宝箱+0.7%消费福利（每日流水）+专属铭牌标识
            <br />
            VIP6充值:50001~80000 D币
            <br />奖励:VIP6等级宝箱+0.8%消费福利（每日流水）+专属铭牌标识<br />
            VIP7充值:80001~120000 D币
            <br />奖励:VIP7等级宝箱+0.9%消费福利（每日流水）+专属铭牌标识<br />
            VIP8充值:120001 D币 以上
            <br />奖励:等级宝箱+1%消费福利（每日流水）+专属铭牌标识
          </div>
        </div>
      </CompPopup>
    </van-overlay>
  </div>
</template>

<script>
import {
  Welfare,
  Box_detail,
  BoxUnpackingRecord,
  WelfareOpen,
  Cash,
  getuservipdataApi,
  getawards_recordsApi,
} from "../../network/api";
import GoodsList from "@/components/PubOpen/GoodsList.vue";
import AOpenBox from "@/components/singleBox/AOpenBoxNew.vue";
import { mapActions, mapState } from "vuex";
import OpeningBox from "../../views/OpeningBox/index.vue";
import QRCode from "qrcode";
import html2canvas from "html2canvas";
import { drawToPic } from "./Util";
export default {
  data() {
    return {
      changetypelist: [
        {
          id: 3,
          title: "VIP",
        },
        {
          id: 1,
          title: "分享奖励",
        },
        {
          id: 2,
          title: "福利宝箱",
        },
      ],
      actypeindex:3,
      cdkvalue: "",
      welfareboxdata: [],
      ItemInfo: {},
      List: [],
      changeShow: true,

      Tshow: false,
      ItemShuJv: [], //奖品信息

      vipdata: {},

      awards_records: [], //下级信息
      userdata: {},

      timeacindex: 0,
      timedata: [
        {
          id: 1,
          title: "今日",
        },
        {
          id: 2,
          title: "昨日",
        },
      ],
      page: 1,
      total: 10,
      per_page: 15,

      starttiem: "",
      endtime: "",
      today: "", //今日
      yesterday: "", //昨日
      beforeYesterday: "", //前天
      value1: "",
      istimeclick: false,

      isshowerweima: false,
      showerweima: "",

      istipsshow: false,

      vipimgdata: [
        {
          id: 0,
          img: require("../../assets/newImages/vip-v0.png"),
        },
        {
          id: 1,
          img: require("../../assets/newImages/vip-v1.png"),
        },
        {
          id: 2,
          img: require("../../assets/newImages/vip-v2.png"),
        },
        {
          id: 3,
          img: require("../../assets/newImages/vip-v3.png"),
        },
        {
          id: 4,
          img: require("../../assets/newImages/vip-v4.png"),
        },
        {
          id: 5,
          img: require("../../assets/newImages/vip-v5.png"),
        },
        {
          id: 6,
          img: require("../../assets/newImages/vip-v6.png"),
        },
        {
          id: 7,
          img: require("../../assets/newImages/vip-v7.png"),
        },
        {
          id: 8,
          img: require("../../assets/newImages/vip-v8.png"),
        },
      ],
    };
  },
  components: {
    GoodsList,
    AOpenBox,
    OpeningBox,
    QRCode,
  },
  computed: {
    ...mapState(["user"]),
    Url() {
      return `${location.origin}/Home?IvtCode=${this.user.invite_code}`;
    },
    UrlCode() {
      return `${this.user.invite_code}`;
    },
    share_money() {
      return `${this.user.promotion_total}`;
    },
    share_user_count() {
      return `${this.user.share_user_count}`;
    },
  },
  created() {
    this.getboxlist();
    this.getawards_records();
    this.gettimedata();
  },

  methods: {
    showtips() {
      this.istipsshow = !this.istipsshow;
    },
    saveclick() {
      const element = this.$refs.capture;
      drawToPic(element);
      // html2canvas(element, {
      //   useCORS: true,
      //   allowTaint: true,
      // })
      //   .then((canvas) => {
      //     canvas.toBlob(
      //       (blob) => {
      //         const link = document.createElement("a");
      //         link.href = URL.createObjectURL(blob);
      //         link.download = "capture.jpg"; // 文件名
      //         document.body.appendChild(link);
      //         link.click();
      //         document.body.removeChild(link);
      //         URL.revokeObjectURL(link.href); // 释放内存
      //       },
      //       "image/jpeg",
      //       1.0
      //     ); // 1.0 表示最高质量
      //   })
      //   .catch((error) => {
      //     console.error("Error capturing the element:", error);
      //   });
      this.isshowerweima = false;
    },
    shareimg() {
      this.isshowerweima = true;
      QRCode.toDataURL(this.Url)
        .then((url) => {
          this.showerweima = url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async textCopy(t) {
      let textArea = document.createElement("textarea");
      textArea.value = t;
      textArea.style.position = "absolute";
      textArea.style.opacity = 0;
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      let success = false;
      try {
        success = document.execCommand("copy");
      } catch (err) {
        success = false;
      }
      textArea.remove();
      if (success) {
        this.$message.success("复制成功！");
      } else {
        this.$message.error("复制失败，请手动复制！");
      }
    },
    changetime(v) {
      (this.starttiem = v[0]), (this.endtime = v[1]);
      this.getawards_records();
      this.istimeclick = false;
    },
    gettimedata() {
      // 获取当前日期
      const today = new Date();

      // 获取今日的年、月、日
      const todayYear = today.getFullYear();
      const todayMonth = String(today.getMonth() + 1).padStart(2, "0"); // 月份从0开始
      const todayDate = String(today.getDate()).padStart(2, "0");

      // 计算昨日的日期
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      const yesterdayYear = yesterday.getFullYear();
      const yesterdayMonth = String(yesterday.getMonth() + 1).padStart(2, "0");
      const yesterdayDate = String(yesterday.getDate()).padStart(2, "0");

      // 计算前天的日期
      const beforeYesterday = new Date(today);
      beforeYesterday.setDate(today.getDate() - 2);

      const beforeYesterdayYear = beforeYesterday.getFullYear();
      const beforeYesterdayMonth = String(
        beforeYesterday.getMonth() + 1
      ).padStart(2, "0");
      const beforeYesterdayDate = String(beforeYesterday.getDate()).padStart(
        2,
        "0"
      );

      // 格式化日期为 YYYY-MM-DD
      const todayFormatted = `${todayYear}-${todayMonth}-${todayDate}`;
      const yesterdayFormatted = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDate}`;
      const beforeYesterdayFormatted = `${beforeYesterdayYear}-${beforeYesterdayMonth}-${beforeYesterdayDate}`;
      this.today = todayFormatted;
      this.yesterday = yesterdayFormatted;
      this.beforeYesterday = beforeYesterdayFormatted;
    },
    handleCurrentChange(v) {
      this.page = v.page;
    },
    timeclick(v) {
      this.timeacindex = v;
      switch (v) {
        case 1:
          (this.starttiem = this.today), (this.endtime = this.yesterday);
          this.getawards_records();
          break;

        case 2:
          (this.starttiem = this.yesterday),
            (this.endtime = this.beforeYesterday);
          this.getawards_records();

          break;

        case 3:
          this.istimeclick = !this.istimeclick;
          break;
      }
    },
    async getawards_records() {
      let params = {
        page: this.page,
        start: this.starttiem,
        end: this.endtime,
      };
      console.log(params, "afafaf");

      const res = await getawards_recordsApi(params);
      if (res.data.code == 200) {
        this.awards_records = res.data.data.data;
        this.total = Number(res.data.data.total);
        this.per_page = Number(res.data.data.per_page);
      } else {
        this.$message.error(res.data.message);
      }

      console.log(res);
    },

    govipbox() {
      this.$router.push("/Vipbox");
    },
    ...mapActions({
      MyInfo: "Refresh",
    }),

    changeclick(v) {
      this.actypeindex = v;
    },

    async getboxlist() {
      let params = {
        type: 4,
      };
      const res = await Welfare(params);
      this.welfareboxdata = res.data.data[0];
      this.getdetail();
      const vip = await getuservipdataApi();
      if (vip.data.code == 200) {
        this.vipdata = vip.data.data;
      } else {
        this.$message.error(vip.data.message);
      }
      console.log(this.vipdata, "vip");
      let data = JSON.parse(localStorage.getItem("boxUserInfo"));
      this.userdata = data.user_data;
    },
    getdetail() {
      Box_detail(this.welfareboxdata.box_id).then((res) => {
        this.ItemInfo = res.data.data;
        this.List = (res.data?.data?.contains || []).map((item) => {
          return {
            lv: item.level,
            ...item,
            ...item.skins,
          };
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.welfarebox {
  overflow: hidden;
  padding-top: 0.35rem;
  .center {
    width: 3.5rem;
    margin: 0.2rem auto;
    .changebox {
      width: 100%;
      height: 0.38rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #161514;
      padding: 0 0.04rem;
      .changeitem {
        width: 0.96rem;
        height: 0.3rem;
        background: url("../../assets/newImages/record-table.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 0.12rem;
        color: #b79f79;
      }
      .accahngeitem {
        background: url("../../assets/newImages/record-actable.png") no-repeat;
        background-size: 100% 100%;
        color: #ffffff;
      }
    }
    .contnentbox {
      width: 100%;
      .topbox {
        width: 100%;
        height: 1.5rem;
        background: rgba(26, 18, 4, 0.6);
        border: 0.01rem solid #817043;
        margin-top: 0.1rem;
        padding: 0 0.1rem;
        .upbox {
          display: flex;
          align-items: center;
          margin-top: 0.12rem;
          justify-content: space-between;
          .lbox {
            display: flex;
            align-items: center;
            img {
              width: 0.48rem;
              height: 0.48rem;
              border-radius: 50%;
              margin-right: 0.08rem;
            }
            .viptitle {
              width: 0.62rem;
              height: 0.2rem;
              .vipv {
                width: 0.62rem;
                height: 0.2rem;
                border-radius: 0;
              }
            }
          }
          .rbox {
            width: 0.7rem;
            height: 0.3rem;
            background: linear-gradient(
              90deg,
              #8f6c3c 47%,
              rgba(143, 108, 60, 0) 100%
            );
            display: flex;
            align-items: center;
            border-radius: 0.15rem 0 0 0.15rem;
            padding: 0 0.08rem;
            img {
              width: 0.14rem;
              height: 0.14rem;
              margin-right: 0.04rem;
            }
            p {
              font-weight: 500;
              font-size: 0.12rem;
              color: #ffedd1;
            }
          }
        }
        .downbox {
          width: 100%;
          margin-top: 0.2rem;
          .tbox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 0.12rem;
            color: #ffffff;
            margin-bottom: 0.12rem;
          }
          .cbox {
            width: 100%;
            height: 0.03rem;
            background: rgba(26, 18, 4, 0.7);
            border-radius: 0.015rem 0.015rem;
            position: relative;
            .line {
              position: absolute;
              top: 0;
              left: 0;
              width: 1rem;
              height: 0.03rem;
              background: linear-gradient(
                90deg,
                #fae277 0%,
                #93d448 29%,
                #c87f11 64%,
                #fae277 100%
              );
              border-radius: 0.015rem 0.015rem;
            }
          }
          .bbox {
            font-weight: 400;
            font-size: 0.1rem;
            color: #ffedd1;
            margin-top: 0.12rem;
          }
        }
      }
      .bottombox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.2rem;
        .items {
          width: 1.6rem;
          height: 2rem;
          @include bgImg("vip-itemsbg");
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-wrap: wrap;
          .itemstitle {
            width: 100%;
            height: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.6rem;
              height: 0.2rem;
            }
          }
          .consumer {
            font-weight: 500;
            font-size: 0.14rem;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            span {
              color: rgba(237, 215, 107, 1);
            }
            .consumertext {
              margin-top: 0.12rem;
            }
          }
          .clickitem {
            width: 1rem;
            height: 0.3rem;
            @include bgImg("seat-not-ready-icon");
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 0.12rem;
            color: #161514;
          }
        }
      }
      .usershare {
        width: 100%;
        height: 0.8rem;
        display: flex;
        align-items: center;
        background: rgba(42, 27, 0, 0.8);
        border: 0.01rem solid #817043;
        margin: 0.12rem 0;
        .infoitem {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          .infotext {
            font-weight: 400;
            font-size: 0.1rem;
            .text {
              color: #ffedd1;
            }
            .numbox {
              margin-top: 0.15rem;
              text-align: center;
            }
          }
        }
        .sline {
          width: 0.01rem;
          height: 0.32rem;
          @include bgImg("sline");
        }
      }
      .sharebtnbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .shareitem {
          width: 1.6rem;
          height: 0.65rem;
          background: rgba(29, 19, 0, 0.6);
          border-left: 0.01rem solid #ffd71d;
          display: flex;
          align-items: center;
          .lbg {
            width: 0.5rem;
            height: 100%;
            background: linear-gradient(
              90deg,
              #ffd71d 0%,
              rgba(255, 215, 29, 0) 100%
            );
            opacity: 0.3;
          }
          .btninfo {
            font-weight: 400;
            font-size: 0.12rem;
            color: #ffedd1;
            p {
              text-align: center;
            }
            .copybtn {
              width: 0.64rem;
              height: 0.22rem;
              @include bgImg("seat-not-ready-icon");
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 500;
              font-size: 0.12rem;
              color: #151413;
              margin-top: 0.1rem;
            }
          }
        }
      }
      .timeclickbox {
        width: 100%;
        height: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .timeitem {
          width: 1.07rem;
          height: 0.22rem;
          @include bgImg("gold-box-bg");
          text-align: center;
          font-weight: 500;
          font-size: 0.12rem;
          color: #ffedd1;
          line-height: 0.22rem;
          position: relative;
          .triangle-down {
            position: absolute;
            right: 0.05rem;
            top: 0.07rem;
            width: 0;
            height: 0;
            border-left: 0.06rem solid transparent; /* 左边透明 */
            border-right: 0.06rem solid transparent; /* 右边透明 */
            border-top: 0.06rem solid #ffffff; /* 顶部颜色 */
          }
        }
        .actimeitem {
          @include bgImg("dust-mall-actypeitembg");
        }
      }
      .tablebox {
        width: 100%;
        .table-title {
          width: 100%;
          height: 0.44rem;
          background: #1d1300;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
          padding-right: 0.05rem;
          .titleitem {
            width: 23%;
            text-align: center;
            position: relative;
          }
          .t2 {
            width: 33%;
          }
        }
        .table-body {
          width: 100%;
          .listitem {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 0.12rem;
            color: #ffffff;
            height: 0.44rem;
            padding-right: 0.12rem;
            .timeitem {
              white-space: nowrap;
            }
            .item {
              width: 25%;
              text-align: center;
            }
            .i2 {
              width: 33%;
            }
          }
          .one {
            background: rgba(55, 46, 29, 0.6);
          }
          .two {
            background: #1d1300;
          }
        }
      }
    }
  }
  .block {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: rgba(0, 0, 0, 0.7);
    .closetime {
      width: 100%;
      height: 1rem;
      margin-top: 0.12rem;
      display: flex;
      justify-content: center;
      .closebtn {
        width: 0.64rem;
        height: 0.22rem;
        @include bgImg("seat-not-ready-icon");
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.12rem;
        color: #151413;
        margin-top: 0.1rem;
      }
    }
    .scharebox {
      width: 3.35rem;

      .erweimatitle {
        width: 100%;
        height: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          width: 0.14rem;
          height: 0.14rem;
        }
      }
      #imgcontent {
        width: 100%;
        height: 3.35rem;
        @include bgImg("sharemabg");
        display: flex;
        align-items: end;
        .userinfobox {
          width: 3.1rem;
          height: 0.88rem;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .userinfo {
            padding-top: 0.3rem;
            display: flex;
            align-items: center;
            .headimgbox {
              width: 0.5rem;
              height: 0.5rem;
              @include bgImg("user-headbg");
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 0.12rem;
              img {
                width: 0.48rem;
                height: 0.48rem;
                border-radius: 50%;
              }
            }
            .usertext {
              color: #ffffff;
              .username {
                font-weight: 500;
                font-size: 0.14rem;
                margin-bottom: 0.05rem;
              }
              p {
                font-family: Alibaba PuHuiTi 2, Alibaba PuHuiTi 20;
                font-weight: normal;
                font-size: 0.12rem;
              }
            }
          }
          .erweima {
            width: 0.84rem;
            height: 0.84rem;
            @include bgImg("erweimabg");
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.72rem;
              height: 0.72rem;
            }
          }
        }
      }
      .btnbox {
        width: 100%;
        height: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.2rem;
        .btn {
          width: 1.5rem;
          height: 0.3rem;
          @include bgImg("seat-not-ready-icon");
          display: flex;
          justify-content: center;
          align-items: center;
          color: #161514;
        }
      }
    }
  }
  .frombox {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    .fromtitle {
      text-align: center;
      font-weight: 500;
      font-size: 0.18rem;
      color: #ffffff;
      margin-bottom: 0.12rem;
    }
    .frominput {
      width: 2.75rem;
      margin: 0 auto;
      font-size: 0.12rem;
    }
  }
}
</style>
