import html2canvas from 'html2canvas';

/**  
* @param {Element} el       截图的dom区域(通过ref获取打的dom)       
* @param {Object} options   插件的一些自定义配置  
* 注意：1、配置项官网地址： https://allenchinese.github.io/html2canvas-docs-zh-cn/docs/html2canvas-configuration.html  
*      2、获取dom的时候需要等待页面加载完成之后  
*      3、ignoreElements 方法会遍历所有节点，我这里给了一个id为clos的结点，因为不需要在截图中留下，就把该节点排除了.  
*/
export function drawToPic(el) {
    const width = parseInt(window.getComputedStyle(el).width);
    const height = parseInt(window.getComputedStyle(el).height);
    const cs = document.createElement('canvas');
    const scale = 1;
    cs.width = width * scale;
    cs.height = height * scale;
    const options = {
        useCORS: true,
        canvas: cs,
        scale: scale,
    };
    html2canvas(el, options).then((canvas) => {

        const url = URL.createObjectURL(base64ToBlob(canvas.toDataURL("image/png")));
        downloadImage(url);
    });
}

// base64转blob  
function base64ToBlob(code) {
    const parts = code.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
}

//下载图片  
function downloadImage(url) {
    // 这里使用 img 是因为在客户端中，不能直接下载，要调用原生的方法  
    const createImg = document.createElement('img');
    createImg.setAttribute('src', url);
    // 如果是在网页中可以直接创建一个 a 标签直接下载  
    const a = document.createElement('a');
    a.href = url;
    // window.open(a.href); //测试时候使用，截图之后新开一个浏览器页面查看是否截图成功  
    a.download = new Date().getTime().toString();  
    a.click();  
}